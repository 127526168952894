import React from 'react';
import CustomButton from './CustomButton';



// React component for displaying pricing information
const Card = ({ cardInfo }) => {
    if (cardInfo.length === 0) {
        return (
            <p></p>
        )
    }

    return (


        <div
            className='uk-card uk-card-default'
            style={{

                width: '300px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                background: '#fff',
                border: '5px solid #4C6ABD',
            }}>


            <div class="uk-card-media-top" style={{ height: '200px', overflow: 'hidden' }}>
                <img src={cardInfo.image} style={{ objectFit: 'cover', minHeight: '100%' }} />
            </div>

            <div style={{
                padding: '20px',
            }}>


                starting at<span style={{ fontSize: '25px', }}>{'  ' + cardInfo.rates}</span>
                <h2 style={{ color: '#4C6ABD', fontSize: '25px', marginBottom: 0 }}>{cardInfo.name}</h2>


                <p style={{ padding: '10px' }}>{cardInfo.details}</p>

                <CustomButton buttonText="Book" borderColor='#4C6ABD' backgroundColor='#11182D' color='#fff' onClick={() => window.location.href = 'tel:+17547799783'} />



            </div>

        </div >

    );
}

export default Card;
