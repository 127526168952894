import React from 'react';
import { ImBoxRemove } from 'react-icons/im';
import services1 from './imgs/services1.webp';
import services2 from './imgs/services2.webp';
import services3 from './imgs/services3.webp';
import services4 from './imgs/services4.webp';

export const pricing = [
    {
        name: 'Paint Correction',
        image: services1,
        icon: <ImBoxRemove size={'100px'} color='#3e414a' />,
        details: (
            <div>
                Expert paint restoration for vehicles.
                <br />
                <br />
                Features:
                <br />
                - Flawless Finish
                <br />
                - High Shine
                <br />
                - Smooth Surface
            </div>
        ),
        rates: '$149',
        popularity: 'Popular'
    },
    {
        name: 'Ceramic Coating',
        image: services2,
        icon: <ImBoxRemove size={'100px'} color='#3e414a' />,
        details: (
            <div>
                Long-lasting protection for your vehicle's paint.
                <br />
                <br />
                Features:
                <br />
                - Durable
                <br />
                - Protective
                <br />
                - High-quality
            </div>
        ),
        rates: '$399'
    },
    {
        name: 'Headlight Restoration',
        image: services3,
        icon: <ImBoxRemove size={'100px'} color='#3e414a' />,
        details: (
            <div>
                Expert headlight restoration service for auto detailing businesses.
                <br />
                <br />
                Features:
                <br />
                - Headlight cleaning
                <br />
                - Lens polishing
                <br />
                - UV sealant
                <br />
                - Bulb Replacement
                <br />
                - Water removal
            </div>
        ),
        rates: '$89'
    },
    {
        name: 'Chrome Polish',
        image: services4,
        icon: <ImBoxRemove size={'100px'} color='#3e414a' />,
        details: (
            <div>
                Restore the shine to your vehicle's chrome accents.
                <br />
                <br />
                Features:
                <br />
                - Shiny
                <br />
                - Gleaming
                <br />
                - Mirror-like
            </div>
        ),
        rates: '$60'
    }
];



export const pricing2 = [
    {
        name: 'Air Cabin Filter Replacement',
        price: '$79',
        startAt: 'Start at',
        details: (
            <div>
                Breathe clean air. Replace your cabin filter.
                <br />
                <br />
                Clean
                <br />
                Fresh
                <br />
                Purify
                <br />
                <br />
                Popular
            </div>
        )
    },
    {
        name: 'Engine Maintenance',
        price: '$109',
        startAt: 'Start at',
        action: 'Book Now',
        details: (
            <div>
                Keep your engine running smoothly with our comprehensive maintenance services.
                <br />
                <br />
                Flash Engine Oil
                <br />
                Oil change
                <br />
                Filter replacement
                <br />
                Fluid top-off
                <br />
                Spark Plugs Replacement
                <br />
                <br />
                Quick
                <br />
                Efficient
                <br />
                Quality
            </div>
        )
    },
    {
        name: 'Brake Change',
        price: '$249',
        startAt: 'Start at',
        action: 'Book Now',
        details: (
            <div>
                Expert brake replacement for auto perfection.
                <br />
                <br />
                Brake Service
                <br />
                Pad Replacement
                <br />
                Rotor Installation
            </div>
        )
    },
    {
        name: 'Spark Plugs Replacement',
        price: '$189',
        startAt: 'Start at',
        action: 'Book Now',
        details: (
            <div>
                Restore your engine's power and efficiency.
                <br />
                <br />
                Quick
                <br />
                Efficient
                <br />
                Quality
            </div>
        )
    }
];
