import React, { useState, useEffect } from 'react';
import navLogo from '../logo.png';
import cover from '../coverImg.jpg';
import CustomButton from './CustomButton';

const Banner = () => {
    const [orientationHorizontal, setOrientationHorizontal] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    useEffect(() => {
        const handleResize = () => {
            setOrientationHorizontal(window.innerWidth >= 800);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            id='home'
            className="h-100" // Use Bootstrap classes for height
            style={{
                backgroundImage: `url(${cover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div fluid className=""> {/* Container can be fluid for full width */}
                <div className="justify-content-center align-items-center h-100">

                    <div xs={12} md={5} className="text-center">
                        <img
                            src={navLogo}
                            alt="Navigation Logo"
                            className="img-fluid" // Bootstrap class for responsive images
                            style={{ width: '400px' }}
                        />


                        <div style={{ color: 'black', paddingBottom: '100px' }}>
                            <div>

                                <p style={{ fontSize: '35px', fontWeight: 'bold' }}>


                                    Revitalize Your Vehicle's Shine
                                </p>
                            </div>
                            <p style={{ fontSize: '30px', transform: 'skewX(-10deg)' }}>


                                Contact Us to get a quote!
                            </p>

                            <CustomButton buttonText="Book Now" backgroundColor='#fff' color='#242424' onClick={() => window.location.href = 'tel:+17547799783'} />
                            <br />
                            <p style={{ fontSize: '25px', }}>

                                Location: <a style={{ color: '#4C6ABD' }} href="#mapModal" onClick={handleShow}>Miami, FL 33020</a><br />

                                Phone: <a style={{ color: '#4C6ABD' }} href="tel:3058505327">(305) 850-5327</a><br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
