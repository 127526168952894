import React, { useState, useEffect, useRef } from 'react';
import CustomButton from './CustomButton';
import { TfiClose } from "react-icons/tfi";
import { CiMenuFries } from "react-icons/ci";

const MenuOverlay = ({ navItems, navElements }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current) {
                const menuRect = menuRef.current.getBoundingClientRect();

                if (
                    !menuRef.current.contains(event.target)
                ) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={menuRef}>
            {
                isOpen ?
                    (
                        <a onClick={toggleMenu}>
                            <TfiClose style={{ margin: '0 20px', color: '#fff', fontSize: '30px' }} />
                        </a>
                    )
                    : (
                        <a onClick={toggleMenu}>
                            <CiMenuFries style={{ margin: '0 20px', color: '#fff', fontSize: '30px' }} />
                        </a>
                    )
            }


            <div
                style={{
                    position: 'fixed',
                    top: '100px',
                    right: isOpen ? '0' : '-300px',
                    width: '250px',
                    backgroundColor: '#202e56',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    transition: 'right 0.3s ease',
                    padding: '25px',
                }}
            >

                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    {navElements}
                    <CustomButton buttonText="Contact Us" backgroundColor='#fff' color='#242424' onClick={() => window.location.href = 'tel:+17547799783'} />

                </div>


            </div>
        </div>
    );
};

export default MenuOverlay;
