import React, { useRef, useEffect } from 'react';
import Nav from '../Components/Nav';
import Banner from '../Components/Banner';
import Section from '../Components/Section';
import { pricing } from '../SiteData';
import CardsSection from '../Components/CardsSecion';
import AboutUs from '../Components/AboutUs';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import WhoWeAre from '../Components/WhoWeAre';


function Home() {
    return (
        <div>
            <Nav />
            <Banner />
            <ParallaxProvider>

                <Parallax speed={15} style={{ height: '300px', overflow: 'hidden' }}>

                    <img src='https://voolt-template-asset.b-cdn.net/assets/2023/01/13235742/industry-hero-car-detailing-1200x800-1.jpg' style={{ objectFit: 'cover', minHeight: '800px', width: '100vw' }} />


                </Parallax>
                <AboutUs />
                <Parallax speed={15} style={{ height: '300px', overflow: 'hidden' }}>

                    <img src='https://cdn-hmghd.nitrocdn.com/sXLqtuSLEawIPwRmNJZmyGBnmlOGqqyq/assets/images/optimized/rev-9344a3f/149881442.v2.pressablecdn.com/wp-content/uploads/2023/06/auto-detailing-car-interior-eps-2048x1367-1-1024x684.jpeg' style={{ objectFit: 'cover', minHeight: '800px', width: '100vw' }} />

                </Parallax>
                <CardsSection id={'services'} title={'Our Services'} background={'#11182D'} titleTextColor={'#E1E6F4'} titleBorderColor={'#4C6ABD'} data={pricing} />
                <Parallax speed={15} style={{ height: '300px', overflow: 'hidden' }}>

                    <img src='https://cdn-hmghd.nitrocdn.com/sXLqtuSLEawIPwRmNJZmyGBnmlOGqqyq/assets/images/optimized/rev-9344a3f/149881442.v2.pressablecdn.com/wp-content/uploads/2022/11/tire-dressing-1-768x576.jpg' style={{ objectFit: 'cover', minHeight: '800px', width: '100vw' }} />


                </Parallax>
                <WhoWeAre />
            </ParallaxProvider>
            <div className='text-center' style={{ display: 'flex', justifyContent: 'center', padding: '50px 0', backgroundColor: '#C4CDE9' }}>
                © Copyright 2024 <br /> Ferero Precision Vehicle Services
            </div>
        </div>

    );
}

export default Home;
