
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Change BrowserRouter to Router and import Routes
import Home from './Pages/Home';


const App = () => (


  <Router>

    <Routes> {/* Wrap the Routes */}
      {/* Route for the homepage */}
      <Route exact path="/" element={<Home />} />

    </Routes>
  </Router>

);

export default App;
