import React, { useState } from 'react';


function WhoWeAre({ }) {


    return (
        <div id='WhoWeAre' className="container " style={{ padding: '50px', }}>
            <div
                style={{

                    display: 'flex',

                    flexWrap: 'wrap',
                }}>
                <h2
                    style={{
                        transform: 'skewX(-10deg)',
                        borderRadius: '15px',
                        padding: '15px',
                        fontSize: '40px',
                        borderBottom: '5px solid #11182D',
                        marginBottom: '25px',
                        color: '#4C6ABD',
                    }}>Who We Are</h2>
            </div>
            <div className="row">
                <div className="col-md-8">

                    <div style={{ paddingBottom: '25px' }}>



                        Welcome to Ferero's Precison Vehicle Services, where automotive excellence meets a new frontier of detailing services. At AstraNova, we're not just professionals – we're enthusiasts, and we've embarked on a fresh journey to redefine the standard of automotive care. Embracing the endearing term "Ferero" that our clients fondly use for our team members, we bring a personal touch and a wealth of experience to every vehicle entrusted to our care.


                        <br />
                        <br />
                        As seasoned professionals stepping into a new chapter, our commitment is crystal clear – to make each of our customers not just satisfied but genuinely happy. With a passion for perfection and a keen eye for detail, Ferero's Precision Vehicle Services is poised to elevate your driving experience through our comprehensive Ferero detailing packages.
                        <br />
                        <br />

                        Our objective is simple – to exceed expectations. We understand the importance of your vehicle, and with our fresh perspective and seasoned expertise, we promise a level of service that goes beyond the ordinary. Join us on this exciting journey, where AstraNova isn't just a detailing service; it's a commitment to making our customers happy, one Ferero detailing session at a time.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhoWeAre;