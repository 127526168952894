import React, { useState } from 'react';


function AboutUs({ }) {


    return (
        <div id='aboutUs' className="container " style={{ padding: '50px', }}>
            <div
                style={{

                    display: 'flex',

                    flexWrap: 'wrap',
                }}>
                <h2
                    style={{
                        transform: 'skewX(-10deg)',
                        borderRadius: '15px',
                        padding: '15px',
                        fontSize: '40px',
                        borderBottom: '5px solid #11182D',
                        marginBottom: '25px',
                        color: '#4C6ABD',
                    }}>About Us</h2>
            </div>
            <div className="row">
                <div className="col-md-8">

                    <div style={{ paddingBottom: '25px' }}>



                        At Ferero Services, we understand that your vehicle is more than just a mode of transportation; it's a reflection of your personality and style. That's why our team of experienced professionals is dedicated to delivering exceptional results every time. We use only top-of-the-line products and cutting-edge techniques to ensure that your vehicle looks its absolute best, inside and out.
                        <br />
                        <br />
                        Whether you're in need of a thorough interior cleaning to restore that showroom shine, paint correction to eliminate swirls and scratches, or a long-lasting ceramic coating to protect your investment, you can trust Ferero to exceed your expectations and elevate your driving experience.
                        <br />
                        <br />

                        So why wait? Experience the Ferero difference today and see why we're the trusted choice for mobile auto detailing in Fort Lauderdale and beyond.

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;